export default {
  name: 'InicioEmpresa',
  meta () {
    return {
      title: 'Empresa Netcarros',
      meta: [
        { 'name': 'robots', 'content': 'noindex' }
      ]
    }
  }
}
